<template>
  <div class="menu view content-padding">

    <SvgLoader v-if="!isDataLoaded" :loading="true"  :bgColor="'transparent'" :width="'100%'"/>

    <div v-if="dataArray.length == 0">
      {{ labels.no_data }}
    </div>
    <TableCardLS
      v-else
      class="menu__table"
      v-bind:settings="settings"
      v-bind:data-array="dataArray"
    />

    <div class="text-right">
      <Button
        v-if="button"
        class="button menu-create"
        :bgColor="button.bgColor"
        :fontWeight="button.fontWeight"
        :padding="button.padding"
        :label="labels.new_menu"
        @click="create"
      />
    </div>

    <div v-if="items.total / (items.limit || 20) > 1" class="menu__pagination">
      <Pagination
        :total-rows="items.total"
        :per-menu="items.limit || 20"
        :current-pagination="pagination"
        @paginationChanged="paginationChanged"
      />
    </div>

    <router-view name="create_new_menu"></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Button from "@/components/Buttons/Button";
import Pagination from "@/components/Helpers/Pagination";
import TableCardLS from "@/components/Table/TableCardLS";
import SvgLoader from "@/components/Preloaders/SvgLoader";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const colors = require("/colors.config.json");

library.add(faExternalLinkAlt);

export default {
  name: "Menu",
  components: {
    TableCardLS,
    Button,
    Pagination,
    SvgLoader
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
      if (from.name) {
        sessionStorage.setItem("menu-pagination", "1");
      }
    });
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (Number(newPage) !== Number(oldPage)) {
        sessionStorage.setItem("menu-pagination", oldPage);
        this.getList();
        this.setQueryPage();
      }
    },
    $route() {
      this.getList();
    },
  },
  computed: {
    ...mapGetters("helper", ["hasPermissions", "labels"]),
    ...mapGetters("menu", {
      getDataArray: "getDataArray",
    }),
    settings() {
      return {
        header: [
          {
            headerLabel: this.labels.title,
            headerColor: `${colors.gray_text}`,
            key: "name",
            computeFn: (input) => input,
            bodyColor: "#2C3E50",
            columnWidth: "1 1 45%",
          },
          {
            headerLabel: this.labels.description,
            headerColor: `${colors.gray_text}`,
            key: "description",
            computeFn: (input) => input,
            bodyColor: "#2C3E50",
            columnWidth: "1 1 45%",
          },
        ],
        actionsWidth: "0 0 6%",
        actionsHeaderLabel: "",
        actions: [],
      };
    },
    currentPage() {
      let page = 1;

      if (this.pagination) {
        page = this.pagination;
      } else if (this.$route.query.page) {
        page = this.$route.query.page;
      }

      return page;
    },
    isAllowed() {
      return this.hasPermissions(["super", "web"]);
    },
  },
  beforeMount() {
    // Redirect if user don't have permissions
    if (!this.isAllowed) {
      this.$router.push({ name: "dashboard" });
    }
  },
  mounted() {
    this.pagination = Number(sessionStorage.getItem("menu-pagination"));
    if (this.pagination < 1) this.pagination = 1;
    this.setQueryPage();
  },
  data() {
    return {
      button: {
        padding: "8px 20px",
        bgColor: `${colors.navy}`,
        fontWeight: "600",
      },
      lang: {},
      dataArray: [],
      pagination: 1,
      items: {},
      permissions: {},
      isDataLoaded: false
    };
  },
  methods: {
    ...mapActions("menu", {
      getMenuList: "getMenuList",
    }),
    ...mapMutations("helper", {
      setBreadcrumbs: "setBreadcrumbs",
    }),
    getList() {
      // console.log('asdf')
      this.isDataLoaded = false
      let self = this;
      this.getMenuList({ page: this.currentPage }).then((data) => {
        if (data.success) {
      this.isDataLoaded = true
          self.dataArray = self.getDataArray(data.data.data);
          self.items = data.data.items;
          self.pagination = data.data.pages.current;
          // self.permissions = data.data.permissions;
        }
      });
    },
    create() {
      this.$router.push({ name: "create_new_menu" });
    },
    setQueryPage() {
      if (!this.$route.query.page) {
        this.$router.replace({ query: { page: String(this.currentPage) } });
      } else if (
        this.$route.query.page &&
        Number(this.$route.query.page) !== Number(this.currentPage)
      ) {
        this.$router.push({ query: { page: String(this.currentPage) } });
      }
    },

    paginationChanged(value) {
      this.pagination = value;
    },
  },
  beforeDestroy() {
    this.setBreadcrumbs("");
  },
};
</script>

<style lang="scss" scoped>
.menu {
  &-create {
    margin-top: 20px;

    @media (min-width: 992px) {
      margin-top: 30px;
    }
    @media (min-width: 1600px) {
      margin-top: 40px;
    }
  }
  &__pagination {
    margin-top: auto;

    ::v-deep {
      .pagination {
        width: fit-content;
        margin: 20px auto 0;

        @media (min-width: 992px) {
          margin: 30px auto 0;
        }
        @media (min-width: 1600px) {
          margin: 40px auto 0;
        }
      }
    }
  }
}
</style>
